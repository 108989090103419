import { useEffect } from "react";

declare namespace Gtag {
  export type Event = [string, any, object?];
}

interface Tracker {
  get: (key: string) => string;
}

export const useGoogleAnalyticsClientID = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: Gtag.Event) {
      window.dataLayer.push(args);
    }

    gtag("js", new Date());

    gtag("config", "GTM-K9T492S", {
      custom_map: { dimension1: "client_id" },
    });

    const getClientId = (): string | undefined => {
      // @ts-ignore
      if (!window.ga || !ga.create) {
        return;
      }
      // @ts-ignore
      const trackers: Tracker[] = ga.getAll();
      let clientId;
      trackers.forEach((tracker) => {
        if (tracker.get("trackingId") === "GTM-K9T492S") {
          clientId = tracker.get("clientId");
        }
      });

      return clientId;
    };

    const maxRetries = 3;
    const retryInterval = 3000;

    function checkClientId(attempts = maxRetries) {
      if (!attempts) return;

      function retry() {
        setTimeout(() => checkClientId(attempts - 1), retryInterval);
      }

      try {
        const clientId = getClientId();
        if (!clientId) {
          retry();
          return;
        }

        gtag("event", "client_id_dimension", { client_id: clientId });
        document.cookie = `_ga_cid=${clientId}; expires=${new Date(
          new Date().getTime() + 365 * 24 * 60 * 60 * 1000
        ).toUTCString()}; path=/`;
      } catch (error) {
        console.error(error);
        retry();
      }
    }

    checkClientId();
  }, []);
};
