import "../styles/globals.css";
import "../styles/slider.css";
import "../styles/phone-input.css";
import { ComponentClass, ReactPropTypes } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useGoogleAnalyticsClientID } from "../utils/ga.utils";
import { createContext, useState, useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { addUTMParamsToUrl } from "../utils/utm-params.utils";

interface AppProps {
  Component: ComponentClass;
  pageProps: ReactPropTypes;
}

const RECAPTCH_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCH_SITE_KEY!;
export const CountryContext = createContext("");

function GuideDAOApp({ Component, pageProps }: AppProps) {
  const [defaultCountry, setDefaultCountry] = useState("");
  const router = useRouter();

  useEffect(() => {
    if (typeof window === "undefined" || !window.localStorage) {
      return;
    }

    const query = router.query;
    const utmParams = Object.keys(query)
      .filter((key) => key.startsWith("utm_"))
      .reduce((params, utm) => ({ ...params, [utm]: query[utm] }), {});

    if (Object.keys(utmParams).length > 0) {
      window.localStorage.setItem("utmParams", JSON.stringify(utmParams));
      return;
    }

    const storedParams = window.localStorage.getItem("utmParams");
    if (!storedParams) {
      return;
    }

    const utmParamsFromStorage = JSON.parse(storedParams);
    addUTMParamsToUrl(utmParamsFromStorage);
  }, [router.query]);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch("https://ipapi.co/json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const countryCodeFromIP = data.country_code;
        setDefaultCountry(countryCodeFromIP.toLowerCase());
      } catch (error) {
        console.error("Ошибка при получении данных от ipapi.co:", error);
        Sentry.captureException(
          "Ошибка при получении данных от ipapi.co:",
          error
        );

        setDefaultCountry("ru");
      }
    };

    fetchCountryCode();
  }, []);

  useGoogleAnalyticsClientID();
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCH_SITE_KEY}>
      <CountryContext.Provider value={defaultCountry}>
        <Component {...pageProps} />
      </CountryContext.Provider>
    </GoogleReCaptchaProvider>
  );
}

export default GuideDAOApp;
