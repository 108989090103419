interface UTMParams {
  [key: string]: string;
}

export function addUTMParamsToUrl(utmParams: UTMParams) {
  const currentUrl = new URL(window.location.href);
  let paramsChanged = false;

  for (const [key, value] of Object.entries(utmParams)) {
    if (!currentUrl.searchParams.has(key)) {
      currentUrl.searchParams.set(key, value);
      paramsChanged = true;
    }
  }

  if (paramsChanged) {
    window.history.replaceState(null, "", currentUrl.toString());
  }
}
